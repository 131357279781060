import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { Loader } from "../shared/components/loader";
import { TemporaryMain } from "../temporaryMain";

interface props {}

export const Routes: React.FC<props> = () => {
  return (
    <Switch>
      <Route exact path="/">
        <TemporaryMain />
      </Route>
      <Route path="/admin">
        {({ match }) => (
          <Switch>
            <Route exact path={`${match?.path}/loader`} component={Loader} />
            <Redirect to="/" />
          </Switch>
        )}
      </Route>
      <Redirect to="/" />
    </Switch>
  );
};
