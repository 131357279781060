import React from "react";
import { Routes } from "./routes/routes";
// import { Routes } from "./routes/routes";

export interface Props {}

const App: React.FC<Props> = () => {
  return <Routes />;
};

export default App;
