// this file is a temporary landing page for the root. Feel free to change, rename, and edit

import axios from "axios";
import React, { useEffect, useState } from "react";
import { Loader } from "./shared/components/loader";
const BASE_URL = process.env.REACT_APP_API_URL || "http://localhost:3001/api";

interface tempProps {}
export const TemporaryMain: React.FC<tempProps> = () => {
  const [msg, setMessage] = useState("");
  useEffect(() => {
    const getMain = async () => {
      const res = await axios.get(`${BASE_URL}`);
      console.log(res.data);
      setMessage(res.data);
    };
    getMain();
  }, []);
  return (
    <div className="App">
      <p>START EDITING IN frontend/src/temporaryMain.tsx</p>
      <p>Message: {msg}</p>
      <p>See our loader below!</p>
      <Loader />
    </div>
  );
};
