import * as React from "react";
import "./styles.css";
interface Props {}

export const Loader: React.FC<Props> = () => {
  return (
    <div className="loader">
      <div className="container">
        <div className="ball"></div>
        <div className="shadow"></div>
      </div>
    </div>
  );
};
